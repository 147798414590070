import { useState, useEffect, useRef, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { StepComponentProps } from 'types';
import { questionAnswerSchema, questionAnswerType } from 'types/zod';
import { IQuestion } from 'types/api/applications';

import { zodResolver } from '@hookform/resolvers/zod';

import { FaSave, FaUserEdit } from 'react-icons/fa';

import FormStepManager from 'components/Application/Form/FormStepManager/FormStepManager';

import BlankLoader from 'components/Shared/Spinner/BlankLoader';
import { object } from 'zod';
import { Alert } from 'components/Shared/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useApplication } from 'context/applicationContext';

function DescriptionBox({ questions, errors }: { questions: IQuestion[]; errors: { [key: string]: any } }) {
  const { t } = useTranslation();
  const numErrors: number = useMemo(() => {
    return Object.keys(errors?.personalProfile || {}).length;
  }, [errors]);

  const progressPercentage: number = useMemo(() => {
    return Math.round(((questions.length - numErrors) / questions.length) * 100);
  }, [questions, numErrors]);

  return (
    <div className="animate-fade-in-down md:animate-fade-in-left mt-4 md:mt-0 flex w-full md:w-4/12 flex-col items-center md:items-start gap-4">
      <h3 className="flex flex-row items-center text-black font-bold text-xl">
        <FaUserEdit />
        <span className="pl-2">{t('PersonalProfile.a1')}</span>
      </h3>
      <p className="text-black font-medium text-md">{t('PersonalProfile.a2')}</p>
      <ul className="list-disc text-black font-medium text-md pl-4">
        <li>{t('PersonalProfile.a3')}</li>
        <li>{t('PersonalProfile.a4')}</li>
      </ul>
      <div className="w-full">
        <div
          className={`flex flex-row justify-between items-center mb-2 ${
            progressPercentage < 70 ? 'text-orange-600' : 'text-green-600'
          }`}
        >
          <p>
            {questions.length - numErrors}/{questions.length}
          </p>
          <p>{progressPercentage.toFixed(1)}%</p>
        </div>

        <div className="h-1  bg-gray-300 mt-4">
          <div
            style={{ width: `${progressPercentage}%` }}
            className={`h-full ${progressPercentage < 70 ? 'bg-orange-600' : 'bg-green-600'}`}
          ></div>
        </div>
        <div className="flex flex-col items-start justify-between mt-4 gap-4">
          <Alert
            status="WARNING"
            title="Attenzione!"
            description="Tornando indietro, perderai le modifiche effettuate in questa pagina: Ricordati di salvare le tue risposte prima di farlo."
          />
        </div>
      </div>
    </div>
  );
}

type FormValues = {
  personalProfile: Array<questionAnswerType & { question_text: string }>;
};

const FormSchema = object({
  personalProfile: questionAnswerSchema.array(),
});

export default function PersonalProfile({
  steps,
  currentStep,
  nextStep,
  prevStep,
  profile,
  profileFetchStatus,
  applicationAppealResidence,
  setProfile,
}: StepComponentProps) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { questions, questionsFetchStatus } = useApplication();
  const { control, register, watch, trigger, clearErrors, setError, setFocus, getValues, handleSubmit, formState } =
    useForm<FormValues>({
      mode: 'onChange',
      resolver: zodResolver(FormSchema),
    });

  const { fields, append, prepend, remove, update, swap, move, insert } = useFieldArray<
    FormValues,
    'personalProfile',
    'id'
  >({
    control: control as any,
    name: 'personalProfile', // unique name for your Field Array
  });

  const [isStepLoading, setIsStepLoading] = useState<boolean>(true);

  const [isStepComplete, setIsStepComplete] = useState<boolean>(false);

  const [filteredQuestions, setFilteredQuestions] = useState<IQuestion[]>([]);

  const fieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fieldRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    setFilteredQuestions(questions);
    if (questions.length) {
      remove();
      if (profile?.questions?.length) {
        if (JSON.stringify(fields) !== JSON.stringify(profile.questions)) {
          const parsedQuestions: (questionAnswerType & {
            question_text: string;
          })[] = [];
          questions.forEach((question) => {
            const relatedAnswer = profile.questions && profile.questions.find((qa) => qa.question_id === question._id);
            parsedQuestions.push({
              question_id: question._id,
              question_text: question.content[i18n.language as 'it' | 'en'],
              response: relatedAnswer?.response || '',
            });
          });
          append(parsedQuestions, { focusIndex: 0 });
        }
      } else {
        const parsedFilteredQ = questions.map((question) => {
          return {
            question_id: question._id,
            question_text: question.content[i18n.language as 'it' | 'en'],
            response: '',
          };
        });
        append(parsedFilteredQ, { focusIndex: 0 });
      }
    }

    trigger();
    setIsStepLoading(false);
  }, [profile, questions]);

  const { errors } = formState;

  useEffect(() => {
    // console.log("errors", errors)
    if (Object.keys(formState.errors).length === 0) setIsStepComplete(true);
    else setIsStepComplete(false);
  }, [formState]);

  const submitForm = () => {
    let parsedAnswers: any[] = [];
    if (errors.personalProfile) {
      getValues().personalProfile.forEach((answer, idx) => {
        const answerHasError =
          errors.personalProfile && Object.keys(errors.personalProfile).some((key) => parseInt(key) === idx);
        if (!answerHasError) parsedAnswers.push(answer);
      });
    } else {
      //no errors
      parsedAnswers = getValues().personalProfile;
    }
    setProfile({ ...profile, questions: parsedAnswers });

    setTimeout(() => navigate(`/application/uploads`), 1000);
  };

  return (
    <div ref={fieldRef}>
      <FormStepManager
        profile={profile}
        steps={steps}
        prevStep={prevStep}
        currentStep={currentStep}
        nextStep={isStepComplete ? submitForm : undefined}
      />
      {isStepLoading ? (
        <BlankLoader />
      ) : (
        <div className="w-full flex flex-col md:flex-row justify-between flex-wrap">
          <DescriptionBox questions={filteredQuestions} errors={errors} />
          <div className="animate-fade-in-up md:animate-fade-in-right w-full md:w-8/12 flex flex-col items-center md:pl-8 gap-4 mt-8 md:mt-0">
            {fields.map((field, idx) => {
              const isError = errors.personalProfile?.[idx]?.response;
              return (
                <div
                  key={field.id}
                  className={`w-full p-2 md:p-4 shadow rounded bg-white border ${
                    isError ? 'border-red-500' : 'border-green-500'
                  } `}
                >
                  <div className="flex flex-col items-start justify-between w-full mb-4 gap-4">
                    <label className="form-label" htmlFor={`personalProfile.${idx}.response`}>
                      {field.question_text}
                    </label>
                    {/* <FaCheckCircle color="green" className="text-xl" /> */}
                    <textarea
                      {...register(`personalProfile.${idx}.response`)}
                      className="form-input"
                      placeholder={t('PersonalProfile.a5')}
                    />
                    <p className="input-error">{errors.personalProfile?.[idx]?.response?.message}</p>
                  </div>
                </div>
              );
            })}
            <button
              className="bluemazza-submit-button md:self-end"
              disabled={!isStepComplete}
              onClick={isStepComplete ? submitForm : undefined}
            >
              {t('PersonalProfile.a6')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
