import React, { useEffect, useState, Fragment } from 'react';
import { FormState } from 'react-hook-form';
import { DegreeSystemType, HighSchoolType, ResidenceMode, StepComponentProps } from 'types';
import { curriculumSchema, curriculumType } from 'types/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import FormStepManager from 'components/Application/Form/FormStepManager/FormStepManager';

import BlankLoader from 'components/Shared/Spinner/BlankLoader';

import { CustomDatePickerInput } from 'components/reactDatepicker';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import { IResidence } from 'types/api/residences';
import { useNavigate } from 'react-router-dom';
import { FaUserGraduate } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function DescriptionBar({
  formState,
  requiredFields,
  applicationAppealResidence,
}: {
  formState: FormState<curriculumType>;
  requiredFields: number;
  studentType?: StudentTypeEnum;
  applicationAppealResidence: IResidence | undefined;
}) {
  const { t } = useTranslation();
  const { errors, isValid: isFormValid } = formState;
  const errorsNumber = Object.keys(errors).length;
  const progressPercentage = ((requiredFields - errorsNumber) / requiredFields) * 100;

  if (!applicationAppealResidence) {
    return null;
  }
  return (
    <div className="animate-fade-in-down md:animate-fade-in-left mt-4 md:mt-0 flex w-full md:w-4/12 flex-col items-center md:items-start gap-4">
      <h3 className="flex flex-row items-center text-black font-bold text-xl">
        <FaUserGraduate />
        <span className="pl-2">{t('Curriculum.a1')}</span>
      </h3>
      {[ResidenceMode.FIRST_ENROLLMENT, ResidenceMode.ALREADY_ENROLLED, ResidenceMode.GRADUATED].includes(
        applicationAppealResidence.mode
      ) ? (
        <>
          <h3 className="text-black font-bold text-md">{t('Curriculum.a2')}</h3>
          <ul className="list-disc text-black font-medium text-md pl-4">
            <li>{t('Curriculum.a3')}</li>
            <li>{t('Curriculum.a4')}</li>
            <li>{t('Curriculum.a5')}</li>
          </ul>
        </>
      ) : null}
      {[ResidenceMode.FIRST_ENROLLMENT].includes(applicationAppealResidence.mode) ? (
        <>
          <h3 className="text-black font-bold text-md">{t('Curriculum.a9')}</h3>
          <ul className="list-disc text-black font-medium text-md pl-4">
            <li>{t('Curriculum.a10')}</li>
          </ul>
        </>
      ) : null}
      {[ResidenceMode.ALREADY_ENROLLED, ResidenceMode.GRADUATED].includes(applicationAppealResidence.mode) ? (
        <>
          <h3 className="text-black font-bold text-md">{t('Curriculum.a6')}</h3>
          <ul className="list-disc text-black font-medium text-md pl-4">
            <li>{t('Curriculum.a7')}</li>
            <li>{t('Curriculum.a8')}</li>
          </ul>
        </>
      ) : null}
      {[ResidenceMode.INTERNATIONAL].includes(applicationAppealResidence.mode) ? (
        <>
          <h3 className="text-black font-bold text-md">{t('Curriculum.a101')}</h3>
          <ul className="list-disc text-black font-medium text-md pl-4">
            <li>{t('Curriculum.a100')}</li>
          </ul>
        </>
      ) : null}
    </div>
  );
}

enum StudentTypeEnum {
  // ResidenceMode.DEFAULT
  firstEnrollment = "Prima iscrizione all'università",
  alreadyEnrolled = 'Studente universitario (già iscritto)',
  // ResidenceMode.INTERNATIONA e ResidenceMode.GRADUATES
  bachelor = 'Bachelor’s degrees',
  postGraduate = 'Post graduate degree', // SPECIALIZZAZIONE
  master = 'Master’s degree',
  phd = 'PhD', // 'DOTTORATO DI RICERCA'
}
export default function Curriculum({
  steps,
  currentStep,
  nextStep,
  prevStep,
  profile,
  profileFetchStatus,
  setProfile,
  applicationAppealResidence,
}: StepComponentProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, unregister, control, setValue, trigger, reset, getValues, watch, handleSubmit, formState } =
    useForm<curriculumType>({
      resolver: zodResolver(curriculumSchema),
      mode: 'all',
    });

  const { errors } = formState;

  const [isStepLoading, setIsStepLoading] = useState<boolean>(true);
  const [hasBachelor, setHasBachelor] = useState<boolean>(false);
  const [isStepComplete, setIsStepComplete] = useState<boolean>(false);

  const [isQuestionsDeleteConfirmationOpen, setIsQuestionsDeleteConfirmationOpen] = useState<boolean>(false);
  const [newStudentType, setNewStudentType] = useState<StudentTypeEnum | undefined>(undefined);

  const fieldRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    fieldRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const resetQuestions = () => {
    setProfile({ ...profile, questions: [] });
  };

  const watchHighSchoolScore = watch('highSchool.finalScore');
  const watchBachelorDegreeScore = watch('university.alreadyEnrolled.hasBachelor.degreeScore');

  useEffect(() => {
    if (!watchHighSchoolScore || watchHighSchoolScore < 100) unregister('highSchool.hasPraise');
  }, [watchHighSchoolScore]);

  useEffect(() => {
    if (!watchBachelorDegreeScore || watchBachelorDegreeScore < 110)
      unregister('university.alreadyEnrolled.hasBachelor.degreeHasPraise');
  }, [watchBachelorDegreeScore]);

  useEffect(() => {
    // console.log(getValues())
    if (!hasBachelor) unregister('university.alreadyEnrolled.hasBachelor');
  }, [hasBachelor]);

  useEffect(() => {
    if (profile) {
      if (applicationAppealResidence?.mode === ResidenceMode.FIRST_ENROLLMENT) {
        setHasBachelor(false);
        unregister('university.alreadyEnrolled');
        unregister('university.international');
        unregister('university.graduate');
      } else if (applicationAppealResidence?.mode === ResidenceMode.ALREADY_ENROLLED) {
        unregister('university.firstUniEnrollment');
        unregister('university.international');
        unregister('university.graduate');
        if (!profile?.curriculum?.university?.alreadyEnrolled?.hasBachelor) {
          setHasBachelor(false);
          unregister('university.alreadyEnrolled.hasBachelor');
        } else setHasBachelor(true);
      } else if (applicationAppealResidence?.mode === ResidenceMode.INTERNATIONAL) {
        unregister('university.alreadyEnrolled');
        unregister('university.firstUniEnrollment');
        unregister('university.graduate');
      }
      reset(profile.curriculum);
    }
    trigger();
    setIsStepLoading(false);
  }, [profile]);

  const submitForm = handleSubmit((data) => {
    setProfile({ ...profile, curriculum: data });
    nextStep && navigate(`/application/form/${nextStep.path}`);
  });

  useEffect(() => {
    if (nextStep && formState.isValid) setIsStepComplete(true);
    else setIsStepComplete(false);
  }, [formState.isValid, nextStep]);

  useEffect(() => {
    if (applicationAppealResidence?.mode !== ResidenceMode.INTERNATIONAL) {
      if (!hasBachelor) unregister('university.alreadyEnrolled.hasBachelor');
      else {
        register('university.alreadyEnrolled.hasBachelor');
        register('university.alreadyEnrolled.hasBachelor.university');
        register('university.alreadyEnrolled.hasBachelor.degree');
        register('university.alreadyEnrolled.hasBachelor.degreeScore');
        register('university.alreadyEnrolled.hasBachelor.degreeHasPraise');
        register('university.alreadyEnrolled.hasBachelor.degreeDate');
      }
    }
  }, [hasBachelor]);

  return (
    <div ref={fieldRef} className="w-full flex flex-col md:flex-row justify-between flex-wrap">
      <FormStepManager
        profile={profile}
        steps={steps}
        prevStep={prevStep}
        currentStep={currentStep}
        nextStep={isStepComplete ? submitForm : undefined}
      />
      {isStepLoading ? (
        <BlankLoader />
      ) : (
        <>
          <DescriptionBar
            formState={formState}
            requiredFields={15}
            applicationAppealResidence={applicationAppealResidence}
          />
          <div className="animate-fade-in-up md:animate-fade-in-right w-full md:w-8/12 flex flex-col items-start md:pl-8 mt-8 md:mt-0">
            {applicationAppealResidence?.mode &&
            ![ResidenceMode.INTERNATIONAL].includes(applicationAppealResidence.mode) ? (
              <>
                <h3 className="text-bluemazza font-bold text-lg mb-4">{t('Curriculum.a14')}</h3>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="w-full mb-4">
                    <label className="form-label required" htmlFor="highSchool.name">
                      {t('Curriculum.a15')}
                    </label>
                    <input
                      {...register('highSchool.name')}
                      className={`form-input ${errors.highSchool?.name && 'error'}`}
                      id="highSchool.name"
                      type="text"
                      placeholder={t('Curriculum.a15')}
                    />
                    <p className="input-error">{errors.highSchool?.name?.message}</p>
                  </div>
                  <div className="w-full mb-4">
                    <label className="form-label required" htmlFor="highSchool.city">
                      {t('Curriculum.a16')}
                    </label>
                    <input
                      {...register('highSchool.city')}
                      className={`form-input ${errors.highSchool?.city && 'error'}`}
                      id="highSchool.city"
                      type="text"
                      placeholder={t('Curriculum.a16')}
                    />
                    <p className="input-error">{errors.highSchool?.city?.message}</p>
                  </div>
                </div>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="w-full">
                    <label className="form-label required" htmlFor="highSchool.type">
                      {t('Curriculum.a17')}
                    </label>
                    <select
                      {...register('highSchool.type')}
                      className={`form-input ${errors.highSchool?.type && 'error'}`}
                      id="highSchool.type"
                    >
                      {Object.keys(HighSchoolType).map((key, idx) => (
                        <option key={idx} value={HighSchoolType[key as keyof typeof HighSchoolType]}>
                          {HighSchoolType[key as keyof typeof HighSchoolType]}
                        </option>
                      ))}
                    </select>
                    <p className="input-error">{errors.highSchool?.type?.message}</p>
                  </div>

                  <div className="w-full">
                    <label className="form-label" htmlFor="highSchool.courseOfStudy">
                      {t('Curriculum.a18')}
                    </label>
                    <input
                      {...register('highSchool.courseOfStudy')}
                      className={`form-input ${errors.highSchool?.courseOfStudy && 'error'}`}
                      id="highSchool.courseOfStudy"
                      type="text"
                      placeholder={t('Curriculum.a18')}
                    />
                  </div>

                  <div className="w-full">
                    <label className="form-label" htmlFor="highSchool.finalScore">
                      {t('Curriculum.a19')}
                    </label>
                    <input
                      {...register('highSchool.finalScore', {
                        setValueAs: (v) => (v ? parseInt(v) : null),
                      })}
                      className={`form-input ${errors.highSchool?.finalScore && 'error'}`}
                      id="highSchool.finalScore"
                      type="number"
                      min={60}
                      max={100}
                      step={1}
                      placeholder={t('Curriculum.a19')}
                    />
                    {watchHighSchoolScore === 100 && (
                      <div className="flex flex-row justify-between items-center p-2">
                        <p>{t('Curriculum.a20')}</p>
                        <input {...register('highSchool.hasPraise')} type="checkbox" className="form-input w-auto" />
                      </div>
                    )}
                    <p className="input-error">{errors.highSchool?.finalScore?.message}</p>
                    {!watchHighSchoolScore && <p className="text-xs text-black">{t('Curriculum.a21')}</p>}
                  </div>
                  <div className="w-full">
                    <label className="form-label required" htmlFor="highSchool.averageScore">
                      {t('Curriculum.a22')}
                    </label>
                    <input
                      {...register('highSchool.averageScore', {
                        valueAsNumber: true,
                      })}
                      className={`form-input ${errors.highSchool?.averageScore && 'error'}`}
                      id="highSchool.averageScore"
                      type="number"
                      min={6}
                      max={10}
                      step={0.1}
                      placeholder={t('Curriculum.a22')}
                    />
                    <p className="input-error">{errors.highSchool?.averageScore?.message}</p>
                  </div>
                </div>
              </>
            ) : null}

            {applicationAppealResidence?.mode === ResidenceMode.FIRST_ENROLLMENT ? (
              <>
                <h3 className="text-bluemazza font-bold text-lg my-4">{t('Curriculum.a23')}</h3>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="mb-4">
                    <label className="form-label required" htmlFor="university.firstUniEnrollment.university">
                      {t('Curriculum.a24')}
                    </label>
                    <input
                      {...register('university.firstUniEnrollment.university')}
                      className={`form-input ${errors.university?.firstUniEnrollment?.university && 'error'}`}
                      id="university.firstUniEnrollment.university"
                      type="text"
                      placeholder={t('Curriculum.a24')}
                    />
                    <p className="input-error">{errors.university?.firstUniEnrollment?.university?.message}</p>
                  </div>
                  <div className="mb-4">
                    <label className="form-label required" htmlFor="university.firstUniEnrollment.degree">
                      {t('Curriculum.a25')}
                    </label>
                    <input
                      {...register('university.firstUniEnrollment.degree')}
                      className={`form-input ${errors.university?.firstUniEnrollment?.degree && 'error'}`}
                      id="university.firstUniEnrollment.degree"
                      type="text"
                      placeholder={t('Curriculum.a25')}
                    />
                    <p className="input-error">{errors.university?.firstUniEnrollment?.degree?.message}</p>
                  </div>
                  <div className="mb-4">
                    <label className="form-label required" htmlFor="university.firstUniEnrollment.degreeSystem">
                      {t('Curriculum.a26')}
                    </label>
                    <select
                      {...register('university.firstUniEnrollment.degreeSystem')}
                      className={`form-input ${errors.university?.firstUniEnrollment?.degreeSystem && 'error'}`}
                      id="university.firstUniEnrollment.degreeSystem"
                    >
                      <option value={DegreeSystemType.MAGISTRALE}>{DegreeSystemType.MAGISTRALE}</option>
                      <option value={DegreeSystemType.TRIENNALE}>{DegreeSystemType.TRIENNALE}</option>
                      <option value={DegreeSystemType.CICLOUNICO}>{DegreeSystemType.CICLOUNICO}</option>
                    </select>
                    <p className="input-error">{errors.university?.firstUniEnrollment?.degreeSystem?.message}</p>
                  </div>
                  <div className="mb-4">
                    <label className="form-label required" htmlFor="university.firstUniEnrollment.degreeYear">
                      {t('Curriculum.a27')}
                    </label>
                    <input
                      {...register('university.firstUniEnrollment.degreeYear', {
                        valueAsNumber: true,
                      })}
                      className={`form-input ${errors.university?.firstUniEnrollment?.degreeYear && 'error'}`}
                      id="university.firstUniEnrollment.degreeYear"
                      type="number"
                      min={1}
                      max={6}
                      step={1}
                      placeholder={t('Curriculum.a27')}
                    />
                    <p className="input-error">{errors.university?.firstUniEnrollment?.degreeYear?.message}</p>
                  </div>
                </div>
              </>
            ) : null}
            {applicationAppealResidence?.mode === ResidenceMode.ALREADY_ENROLLED ? (
              <>
                <h3 className="text-bluemazza font-bold text-lg mb-4">{t('Curriculum.a28')}</h3>
                <div className="flex flex-row w-full justify-start items-center gap-4 mb-4">
                  <input
                    checked={hasBachelor}
                    onChange={(e) => setHasBachelor(e.target.checked)}
                    type="checkbox"
                    className="form-input w-auto"
                    id="hasBachelor"
                  />
                  <label htmlFor="hasBachelor">{t('Curriculum.a29')}</label>
                </div>
                {hasBachelor && (
                  <div className={`w-full grid grid-cols-1 lg:grid-cols-4 gap-4 mb-4`}>
                    <div className="mb-4">
                      <label
                        className="form-label required"
                        htmlFor="university.alreadyEnrolled.hasBachelor.university"
                      >
                        {t('Curriculum.a24')}
                      </label>
                      <input
                        {...register('university.alreadyEnrolled.hasBachelor.university')}
                        className={`form-input ${
                          errors.university?.alreadyEnrolled?.hasBachelor?.university && 'error'
                        }`}
                        id="university.alreadyEnrolled.hasBachelor.university"
                        type="text"
                        placeholder={t('Curriculum.a24')}
                      />
                      <p className="input-error">
                        {errors.university?.alreadyEnrolled?.hasBachelor?.university?.message}
                      </p>
                    </div>
                    <div className="mb-4">
                      <label className="form-label required" htmlFor="university.alreadyEnrolled.hasBachelor.degree">
                        {t('Curriculum.a30')}
                      </label>
                      <input
                        {...register('university.alreadyEnrolled.hasBachelor.degree')}
                        className={`form-input ${errors.university?.alreadyEnrolled?.hasBachelor?.degree && 'error'}`}
                        id="university.alreadyEnrolled.hasBachelor.degree"
                        type="text"
                        placeholder={t('Curriculum.a30')}
                      />
                      <p className="input-error">{errors.university?.alreadyEnrolled?.hasBachelor?.degree?.message}</p>
                    </div>

                    <div className="mb-4">
                      <label
                        className="form-label required"
                        htmlFor="university.alreadyEnrolled.hasBachelor.degreeScore"
                      >
                        {t('Curriculum.a31')}
                      </label>
                      <input
                        {...register('university.alreadyEnrolled.hasBachelor.degreeScore', { valueAsNumber: true })}
                        className={`form-input ${
                          errors.university?.alreadyEnrolled?.hasBachelor?.degreeScore && 'error'
                        }`}
                        id="university.alreadyEnrolled.hasBachelor.degreeScore"
                        type="number"
                        placeholder={t('Curriculum.a31')}
                      />
                      {watchBachelorDegreeScore === 110 && (
                        <div className="flex flex-row justify-between items-center p-2">
                          <p>{t('Curriculum.a20')}</p>
                          <input
                            {...register('university.alreadyEnrolled.hasBachelor.degreeHasPraise')}
                            type="checkbox"
                            className="form-input w-auto"
                          />
                        </div>
                      )}
                      <p className="input-error">
                        {errors.university?.alreadyEnrolled?.hasBachelor?.degreeScore?.message}
                      </p>
                    </div>

                    <div className="mb-4">
                      <label
                        className="form-label required"
                        htmlFor="university.alreadyEnrolled.hasBachelor.degreeDate"
                      >
                        {t('Curriculum.a32')}
                      </label>
                      <Controller
                        control={control}
                        name="university.alreadyEnrolled.hasBachelor.degreeDate"
                        render={({ field }) => (
                          <>
                            {/* <div className='hidden xl:block'> */}
                            <DatePicker
                              placeholderText={t('Curriculum.a33')}
                              dateFormat={'dd/MM/yyyy'}
                              // onChange={(date: Date) => { console.log(dayjs(date).utc().startOf('day').toDate()); field.onChange(dayjs(date).utc().startOf('day').toDate()) }}
                              onChange={(date: Date) => {
                                field.onChange(dayjs(date).utc().startOf('day').toDate());
                              }}
                              selected={field.value}
                              withPortal
                              customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                              showMonthDropdown
                              showYearDropdown
                              minDate={dayjs().subtract(40, 'year').toDate()}
                              maxDate={dayjs().toDate()}
                              dropdownMode="select"
                              locale={'it-IT'}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                            {/* </div> */}
                            {/* <input className={`block xl:hidden form-input ${errors.university?.alreadyEnrolled?.hasBachelor?.degreeDate && 'error'}`} value={field.value && dayjs(field.value).format('YYYY-MM-DD')} onChange={(e) => { const value = e.target.value; console.log(dayjs(value,'YYYY-MM-DD').format('YYYY-MM-DD')); console.log(dayjs(value, 'YYYY-MM-DD').startOf('day').utc(true).toDate()); field.onChange(value && value !== "" ? dayjs(value, 'YYYY-MM-DD').startOf('day').utc(true).toDate() : undefined) }} id="birthday" type="date" placeholder="DD/MM/YYYY" onKeyDown={(e) => e.preventDefault()} /> */}
                            {/* <input className={`block xl:hidden form-input $${errors.university?.alreadyEnrolled?.hasBachelor?.degreeDate && 'error'}`} value={field.value && dayjs(field.value).utc().startOf('day').format('YYYY-MM-DD')} onChange={(e) => { const value = e.target.value; field.onChange(value && value !== "" ? dayjs(value, 'YYYY-MM-DD').utc().startOf('day').toDate() : undefined) }} id="birthday" type="date" placeholder="DD/MM/YYYY" onKeyDown={(e) => e.preventDefault()} /> */}
                          </>
                        )}
                      />

                      <p className="input-error">
                        {errors.university?.alreadyEnrolled?.hasBachelor?.degreeDate?.message}
                      </p>
                    </div>
                  </div>
                )}
                <h3
                  className="text-bluemazza font-bold text-lg mb-4"
                  dangerouslySetInnerHTML={{
                    __html: t('Curriculum.a34', { date: `${dayjs().subtract(1, 'year').year()}/${dayjs().year()}` }),
                  }}
                ></h3>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  <div className="mb-4">
                    <label
                      className="form-label required"
                      htmlFor="university.alreadyEnrolled.academicYears.current.university"
                    >
                      {t('Curriculum.a24')}
                    </label>
                    <input
                      {...register('university.alreadyEnrolled.academicYears.current.university')}
                      className={`form-input ${
                        errors.university?.alreadyEnrolled?.academicYears?.current?.university && 'error'
                      }`}
                      id="university.alreadyEnrolled.academicYears.current.university"
                      type="text"
                      placeholder={t('Curriculum.a24')}
                    />
                    <p className="input-error">
                      {errors.university?.alreadyEnrolled?.academicYears?.current?.university?.message}
                    </p>
                  </div>
                  <div className="mb-4">
                    <label
                      className="form-label required"
                      htmlFor="university.alreadyEnrolled.academicYears.current.degree"
                    >
                      {t('Curriculum.a25')}
                    </label>
                    <input
                      {...register('university.alreadyEnrolled.academicYears.current.degree')}
                      className={`form-input ${
                        errors.university?.alreadyEnrolled?.academicYears?.current?.degree && 'error'
                      }`}
                      id="university.alreadyEnrolled.academicYears.current.degree"
                      type="text"
                      placeholder={t('Curriculum.a25')}
                    />
                    <p className="input-error">
                      {errors.university?.alreadyEnrolled?.academicYears?.current?.degree?.message}
                    </p>
                  </div>

                  <div className="mb-4">
                    <label
                      className="form-label required"
                      htmlFor="university.alreadyEnrolled.academicYears.current.degreeSystem"
                    >
                      {t('Curriculum.a26')}
                    </label>
                    <select
                      {...register('university.alreadyEnrolled.academicYears.current.degreeSystem')}
                      className={`form-input ${
                        errors.university?.alreadyEnrolled?.academicYears?.current?.degreeSystem && 'error'
                      }`}
                      id="alreadyEnrolled.academicYears.current.degreeSystem"
                      placeholder={t('Curriculum.a26')}
                    >
                      <option value={DegreeSystemType.MAGISTRALE}>{DegreeSystemType.MAGISTRALE}</option>
                      <option value={DegreeSystemType.TRIENNALE}>{DegreeSystemType.TRIENNALE}</option>
                      <option value={DegreeSystemType.CICLOUNICO}>{DegreeSystemType.CICLOUNICO}</option>
                    </select>
                    <p className="input-error">
                      {errors.university?.alreadyEnrolled?.academicYears?.current?.degreeSystem?.message}
                    </p>
                  </div>
                  <div className="mb-4">
                    <label
                      className="form-label required"
                      htmlFor="university.alreadyEnrolled.academicYears.current.degreeYear"
                    >
                      {t('Curriculum.a27')}
                    </label>
                    <input
                      {...register('university.alreadyEnrolled.academicYears.current.degreeYear', {
                        valueAsNumber: true,
                      })}
                      className={`form-input ${
                        errors.university?.alreadyEnrolled?.academicYears?.current?.degreeYear && 'error'
                      }`}
                      id="university.alreadyEnrolled.academicYears.current.degreeYear"
                      type="number"
                      min={1}
                      max={6}
                      step={1}
                      placeholder={t('Curriculum.a27')}
                    />
                    <p className="input-error">
                      {errors.university?.alreadyEnrolled?.academicYears?.current?.degreeYear?.message}
                    </p>
                  </div>
                  <div className="mb-4">
                    <label
                      className="form-label required"
                      htmlFor="university.alreadyEnrolled.academicYears.current.creditsEarned"
                    >
                      {t('Curriculum.a35')}
                    </label>
                    <input
                      {...register('university.alreadyEnrolled.academicYears.current.creditsEarned', {
                        valueAsNumber: true,
                      })}
                      className={`form-input ${
                        errors.university?.alreadyEnrolled?.academicYears?.current?.creditsEarned && 'error'
                      }`}
                      id="university.alreadyEnrolled.academicYears.current.creditsEarned"
                      type="number"
                      min={0}
                      max={360}
                      step={1}
                      placeholder={t('Curriculum.a36')}
                    />
                    <p className="input-error">
                      {errors.university?.alreadyEnrolled?.academicYears?.current?.creditsEarned?.message}
                    </p>
                  </div>
                  <div className="mb-4">
                    <label
                      className="form-label required"
                      htmlFor="university.alreadyEnrolled.academicYears.current.avgExamsScore"
                    >
                      {t('Curriculum.a37')}
                    </label>
                    <input
                      {...register('university.alreadyEnrolled.academicYears.current.avgExamsScore', {
                        valueAsNumber: true,
                      })}
                      className={`form-input ${
                        errors.university?.alreadyEnrolled?.academicYears?.current?.avgExamsScore && 'error'
                      }`}
                      id="university.alreadyEnrolled.academicYears.current.avgExamsScore"
                      type="number"
                      min={1}
                      max={30}
                      step={0.1}
                      placeholder={t('Curriculum.a37')}
                    />
                    <p className="input-error">
                      {errors.university?.alreadyEnrolled?.academicYears?.current?.avgExamsScore?.message}
                    </p>
                  </div>
                </div>
                <h3
                  className="text-bluemazza font-bold text-lg mb-4"
                  dangerouslySetInnerHTML={{
                    __html: t('Curriculum.a38', { date: `${dayjs().year()}/${dayjs().add(1, 'year').year()}` }),
                  }}
                ></h3>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  <div className="w-full mb-4">
                    <label
                      className="form-label required"
                      htmlFor="university.alreadyEnrolled.academicYears.next.university"
                    >
                      {t('Curriculum.a24')}
                    </label>
                    <input
                      {...register('university.alreadyEnrolled.academicYears.next.university')}
                      className={`form-input ${
                        errors.university?.alreadyEnrolled?.academicYears?.next?.university && 'error'
                      }`}
                      id="university.alreadyEnrolled.academicYears.next.university"
                      type="text"
                      placeholder={t('Curriculum.a24')}
                    />
                    <p className="input-error">
                      {errors.university?.alreadyEnrolled?.academicYears?.next?.university?.message}
                    </p>
                  </div>
                  <div className="mb-4">
                    <label
                      className="form-label required"
                      htmlFor="university.alreadyEnrolled.academicYears.next.degree"
                    >
                      {t('Curriculum.a25')}
                    </label>
                    <input
                      {...register('university.alreadyEnrolled.academicYears.next.degree')}
                      className={`form-input ${
                        errors.university?.alreadyEnrolled?.academicYears?.next?.degree && 'error'
                      }`}
                      id="university.alreadyEnrolled.academicYears.next.degree"
                      type="text"
                      placeholder={t('Curriculum.a25')}
                    />
                    <p className="input-error">
                      {errors.university?.alreadyEnrolled?.academicYears?.next?.degree?.message}
                    </p>
                  </div>

                  <div className="mb-4">
                    <label
                      className="form-label required"
                      htmlFor="university.alreadyEnrolled.academicYears.next.degreeSystem"
                    >
                      {t('Curriculum.a26')}
                    </label>
                    <select
                      {...register('university.alreadyEnrolled.academicYears.next.degreeSystem')}
                      className={`form-input ${
                        errors.university?.alreadyEnrolled?.academicYears?.next?.degreeSystem && 'error'
                      }`}
                      id="university.alreadyEnrolled.academicYears.next.degreeSystem"
                      placeholder={t('Curriculum.a26')}
                    >
                      <option value={DegreeSystemType.MAGISTRALE}>{DegreeSystemType.MAGISTRALE}</option>
                      <option value={DegreeSystemType.TRIENNALE}>{DegreeSystemType.TRIENNALE}</option>
                      <option value={DegreeSystemType.CICLOUNICO}>{DegreeSystemType.CICLOUNICO}</option>
                    </select>
                    <p className="input-error">
                      {errors.university?.alreadyEnrolled?.academicYears?.next?.degreeSystem?.message}
                    </p>
                  </div>
                  <div className="mb-4">
                    <label
                      className="form-label required "
                      htmlFor="university.alreadyEnrolled.academicYears.next.degreeYear"
                    >
                      {t('Curriculum.a39')}
                    </label>
                    <input
                      {...register('university.alreadyEnrolled.academicYears.next.degreeYear', { valueAsNumber: true })}
                      className={`form-input ${
                        errors.university?.alreadyEnrolled?.academicYears?.next?.degreeYear && 'error'
                      }`}
                      id="university.alreadyEnrolled.academicYears.next.degreeYear"
                      type="number"
                      min={1}
                      max={6}
                      step={1}
                      placeholder={t('Curriculum.a39')}
                    />
                    <p className="input-error">
                      {errors.university?.alreadyEnrolled?.academicYears?.next?.degreeYear?.message}
                    </p>
                  </div>
                </div>
              </>
            ) : null}
            {applicationAppealResidence?.mode === ResidenceMode.INTERNATIONAL ? (
              <>
                <h3 className="text-bluemazza font-bold text-lg mb-4">
                  To the attention of Collegio Don Mazza’s Director
                </h3>

                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="mb-4">
                    <label className="form-label required" htmlFor="university.graduate.residence">
                      Residence
                    </label>
                    <select
                      {...register('university.international.residence')}
                      className={`form-input ${errors.university?.international?.residence && 'error'}`}
                      id="international.residence"
                      placeholder=""
                    >
                      <option value="Residenze di Padova">Residenze di Padova</option>
                      <option value="Verona - Residenza R. Gentilin">Verona - Residenza R. Gentilin</option>
                      <option value="Verona - Residenza Ferrari Dalle Spade">
                        Verona - Residenza Ferrari Dalle Spade
                      </option>
                      <option value="Roma - Residenza G. Tovini">Roma - Residenza G. Tovini</option>
                    </select>
                    <p className="input-error">{errors.university?.international?.residence?.message}</p>
                  </div>
                </div>
                <h3 className="text-bluemazza font-bold text-lg mb-4">ACADEMIC CURRICULUM RECORD</h3>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="mb-4">
                    <label className="form-label required" htmlFor="university.international.degreeSystem">
                      Declares to be accepted/registered at University for:
                    </label>
                    <select
                      {...register('university.international.degreeSystem')}
                      className={`form-input ${errors.university?.international?.degreeSystem && 'error'}`}
                      id="university.international.degreeSystem"
                    >
                      <option value={DegreeSystemType.phd}>{t('Curriculum.phd')}</option>
                      <option value={DegreeSystemType.postGraduate}>{t('Curriculum.postGraduate')}</option>
                      <option value={DegreeSystemType.master}>{t('Curriculum.master')}</option>
                      <option value={DegreeSystemType.bachelor}>{t('Curriculum.bachelor')}</option>
                    </select>
                    <p className="input-error">{errors.university?.international?.degreeSystem?.message}</p>
                  </div>
                  <div className="mb-4">
                    <label className="form-label required" htmlFor="university.international.cycle">
                      Major/Course of Study (please specify the qualification, cycle..)
                    </label>
                    <select
                      {...register('university.international.cycle')}
                      className={`form-input ${errors.university?.international?.cycle && 'error'}`}
                      id="international.cycle"
                      placeholder=""
                    >
                      <option value={DegreeSystemType.MAJOR}>{DegreeSystemType.MAJOR}</option>
                      <option value={DegreeSystemType.OTHER}>{DegreeSystemType.OTHER}</option>
                    </select>
                    <p className="input-error">{errors.university?.international?.cycle?.message}</p>
                  </div>
                  <div className="mb-4">
                    <label className="form-label required" htmlFor="university.international.degreeYear">
                      Year of the course
                    </label>
                    <select
                      {...register('university.international.degreeYear', {
                        valueAsNumber: true,
                      })}
                      className={`form-input ${errors.university?.international?.degreeYear && 'error'}`}
                      id="university.international.degreeYear"
                    >
                      <option value={1}>{t('Curriculum.a49')}</option>
                      <option value={2}>{t('Curriculum.a50')}</option>
                      <option value={3}>{t('Curriculum.a51')}</option>
                      <option value={4}>{t('Curriculum.a52')}</option>
                      <option value={5}>{t('Curriculum.a53')}</option>
                      <option value={6}>{t('Curriculum.a54')}</option>
                    </select>
                    <p className="input-error">{errors.university?.international?.degreeYear?.message}</p>
                  </div>
                </div>

                <h3 className="text-bluemazza font-bold text-lg mb-4">
                  APPLIES TO BE ADMITTED (4 month sojourn minimum)
                </h3>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="mb-4">
                    <label className="form-label required" htmlFor="university.international.from">
                      From (date dd/mm/yy)*
                    </label>
                    <Controller
                      control={control}
                      name="university.international.from"
                      render={({ field }) => (
                        <>
                          {/* <div className='hidden xl:block'> */}
                          <DatePicker
                            placeholderText={'From'}
                            dateFormat={'dd/MM/yyyy'}
                            // onChange={(date: Date) => { console.log(dayjs(date).utc().startOf('day').toDate()); field.onChange(dayjs(date).utc().startOf('day').toDate()) }}
                            onChange={(date: Date) => {
                              field.onChange(dayjs(date).utc().startOf('day').toDate());
                            }}
                            selected={field.value}
                            withPortal
                            customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            locale={'it-IT'}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </>
                      )}
                    />

                    <p className="input-error">{errors.university?.international?.from?.message}</p>
                  </div>

                  <div className="mb-4">
                    <label className="form-label required" htmlFor="university.international.to">
                      To (date dd/mm/yy)*
                    </label>
                    <Controller
                      control={control}
                      name="university.international.to"
                      render={({ field }) => (
                        <>
                          {/* <div className='hidden xl:block'> */}
                          <DatePicker
                            placeholderText={'To'}
                            dateFormat={'dd/MM/yyyy'}
                            // onChange={(date: Date) => { console.log(dayjs(date).utc().startOf('day').toDate()); field.onChange(dayjs(date).utc().startOf('day').toDate()) }}
                            onChange={(date: Date) => {
                              field.onChange(dayjs(date).utc().startOf('day').toDate());
                            }}
                            selected={field.value}
                            withPortal
                            customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            locale={'it-IT'}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </>
                      )}
                    />

                    <p className="input-error">{errors.university?.international?.to?.message}</p>
                  </div>
                </div>
              </>
            ) : null}

            <h3 className="text-bluemazza font-bold text-lg mb-4">{t('Curriculum.a102')}</h3>
            <div className="flex flex-row w-full justify-start items-center gap-4 mb-4">
              <input
                {...register('highSchoolCertificate')}
                type="checkbox"
                className="form-input w-auto"
                id="hasBachelor"
              />
              <label htmlFor="hasBachelor">{t('Curriculum.a103')}</label>
            </div>
            <div className="flex flex-row w-full justify-start items-center gap-4 mb-4">
              <input
                {...register('degreeCertificate')}
                type="checkbox"
                className="form-input w-auto"
                id="hasBachelor"
              />
              <label htmlFor="hasBachelor">{t('Curriculum.a104')}</label>
            </div>

            <button disabled={!isStepComplete} className="bluemazza-submit-button md:self-end" onClick={submitForm}>
              {t('Curriculum.a41')}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
