import { useAuthentication } from 'context/authContext';
import { useApplication } from 'context/applicationContext';
import { Header } from 'components/header';
import { CreateApplication } from 'components/Application/CreateApplication';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export default function CreateApplicationPage() {
  const { t } = useTranslation();
  const { setUserLogout, userSession } = useAuthentication();
  const { userProfile } = useApplication();

  return (
    <div className="px-4 flex flex-col items-center gap-4 space-y-12">
      <Header setUserLogout={() => setUserLogout(false)} userSession={userSession} userProfile={userProfile} />

      <h3
        className="text-xl font-bold text-bluemazza"
        dangerouslySetInnerHTML={{
          __html: t('CreateApplicationPage.title', { years: `${dayjs().year()}/${dayjs().add(1, 'year').year()}` }),
        }}
      ></h3>

      <CreateApplication />
    </div>
  );
}
