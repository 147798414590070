import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { IApplication } from 'types/api/applications';
import { RequiredDocumentType, IAppeal } from 'types/api/residences';
import { updateUserProfileType } from 'types/zod';

interface IDescriptionSidebar {
  userProfile: updateUserProfileType;
  application: IApplication;
  requiredUploads: RequiredDocumentType[];
  applicationAppeal: IAppeal;
}

const DescriptionSidebar: React.FC<IDescriptionSidebar> = ({ application, requiredUploads, applicationAppeal }) => {
  const { t } = useTranslation();
  const requiredUploadsNumber = requiredUploads.length;
  const uploadsNumber = requiredUploads.filter((requiredUpload) =>
    application.uploads.find((upload) => upload.key === requiredUpload.key)
  ).length;

  const progressPercentage = (uploadsNumber / requiredUploadsNumber) * 100;

  const daysToExpire = dayjs(applicationAppeal?.expiryDate).diff(dayjs(), 'days');
  const isExpired = dayjs(applicationAppeal?.expiryDate).isBefore(dayjs());

  return (
    <div className="md:animate-fade-in-left flex w-full md:w-4/12 flex-col items-start gap-4">
      <h3 className="text-black font-bold text-xl">{t('DescriptionSidebar.title')}</h3>
      <div className="w-full">
        <div
          className={`flex flex-row justify-between items-center my-4 ${
            progressPercentage < 70 ? 'text-orange-600' : 'text-green-600'
          }`}
        >
          <p>
            {uploadsNumber}/{requiredUploadsNumber}
          </p>
          <p>{progressPercentage.toFixed(1)}%</p>
        </div>

        <div className="h-1 bg-gray-300 mt-4">
          <div
            style={{ width: `${progressPercentage}%` }}
            className={`h-full ${progressPercentage < 70 ? 'bg-orange-600' : 'bg-green-600'}`}
          ></div>
        </div>
      </div>
      <p className="text-black font-medium text-md">{t('DescriptionSidebar.description1')}</p>
      <ul className="list-disc text-black font-medium text-md pl-4">
        <li>{t('DescriptionSidebar.description2')}</li>
        <li>
          {t('DescriptionSidebar.description3')}
          <a
            href="https://www.ilovepdf.com/compress_pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="underline hover:text-bluemazza font-medium transition ease duration-300"
          >
            {t('DescriptionSidebar.link')}
          </a>{' '}
          {t('DescriptionSidebar.description4')}
        </li>
      </ul>
      <h5
        className={`pt-2 md:pt-0  font-bold text-center md:text-left ${
          daysToExpire <= 0
            ? 'text-red-500'
            : daysToExpire <= 7
            ? 'text-orange-500'
            : daysToExpire <= 15
            ? 'text-yellow-500'
            : 'text-black'
        } text-md justify-self-end md:justify-self-auto`}
        dangerouslySetInnerHTML={{
          __html:
            dayjs(applicationAppeal?.expiryDate) < dayjs()
              ? t('DescriptionSidebar.description5')
              : t('DescriptionSidebar.description6', {
                  date: dayjs(applicationAppeal.expiryDate).format('DD/MM/YYYY'),
                  days: dayjs(applicationAppeal?.expiryDate).fromNow(),
                }),
        }}
      ></h5>

      {isExpired && (
        <h5
          className={`pt-2 md:pt-0 font-medium text-center md:text-left text-md justify-self-end md:justify-self-auto underline`}
        >
          {t('DescriptionSidebar.description7')}
        </h5>
      )}
    </div>
  );
};

export default DescriptionSidebar;
