import React from 'react';
import { IconType } from 'react-icons/lib';
import { IAppeal, IResidence } from './api/residences';
import { updateUserProfileType } from './zod';

export enum FetchStatus {
  UNDEFINED = 'undefined',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum DegreeSystemType {
  TRIENNALE = 'Triennale',
  MAGISTRALE = 'Magistrale',
  CICLOUNICO = 'Ciclo unico',
  // ResidenceMode.INTERNATIONALIZATION
  MAJOR = 'Major',
  OTHER = 'Other',
  // ResidenceMode.GRADUATE
  bachelor = 'Bachelor’s degrees',
  postGraduate = 'Post graduate degree', // SPECIALIZZAZIONE
  master = 'Master’s degree',
  phd = 'PhD', // 'DOTTORATO DI RICERCA'
}

export enum FamilyMemberType {
  PARENT = 'Genitore',
  SON = 'Figlio/a',
  OTHER = 'Altro',
}

export enum MaritalStatusType {
  CONIGUATO = 'Coniugato/a',
  LIBERO = 'Libero/a',
  CONVIVENTE = 'Convivente',
  VEDOVO = 'Vedovo/a',
  DECEDUTO = 'Deceduto/a',
}

export enum HighSchoolType {
  LICEO = 'Liceo',
  ISTITUTO_TECNICO = 'Istituto Tecnico',
  ISTITUTO_PROFESSIONALE = 'Istituto Professionale',
  SCUOLASERALE = 'Scuola serale',
}

export enum ProfessionType {
  OCCUPATO = 'Occupato',
  PENSIONATO = 'Pensionato',
  INOCCUPATO_ATRO = 'Inoccupato o altro',
  STUDENTE = 'Studente',
  BAMBINO = 'Bambino (0-6 anni)',
}

export enum ProfessionTypeRelationship {
  DIPENDENTE_A_TEMPO_DETERMINATO = 'Dipendente a tempo determinato',
  DIPENDENTE_A_TEMPO_INDETERMINATO = 'Dipendente a tempo indeterminato',
  LAVORO_INTERMITTENTE = 'Lavoro intermittente',
  LAVORO_SOMMINISTRATO = 'Lavoro somministrato',
  COLLABORAZIONI_COORDINATE_E_CONTINUATIVE = 'Collaborazioni coordinate e continuative',
  AUTONOMO_PARTITA_IVA = 'Autonomo - Partita IVA',
  AUTONOMO_IMPRENDITORE = 'Autonomo - Imprenditore',
  PRESTAZIONE_OCCASIONALE = 'Prestazione occasionale',
  APPRENDISTATO = 'Apprendistato',
  TIROCINO_E_STAGE = 'Tirocino e stage',
}

export enum ProfessionContractType {
  PUBBLICO = 'Pubblico',
  PRIVATO = 'Privato',
}

export enum ProfessionSector {
  AERONAUTICA = 'Aeronautica',
  AGROALIMENTARE_ALLEVAMENTO_E_PESCA = 'Agroalimentare, allevamento e pesca',
  AMMINISTRAZIONE_PUBBLICA = 'Amministrazione pubblica',
  ARCHITETTURA_E_COSTRUZIONI = 'Architettura e costruzioni',
  ARTE_E_INTRATTENIMENTO = 'Arte e intrattenimento',
  ARTIGIANATO = 'Artigianato',
  ASSICURATIVO_FINANZIARIO_E_BANCHE = 'Assicurativo-finanziario e banche',
  ASSISTENZA_SOCIALE_E_SERVIZI_ALLA_FAMIGLIA = 'Assistenza sociale e servizi alla famiglia',
  ATTIVITA_IMMOBILIARI = 'Attività immobiliari',
  COMMERCIO = 'Commercio',
  CULTURA = 'Cultura',
  FORZE_DELL_ORDINE_ESERCITO = "Forze dell'ordine/Esercito",
  GIURIDICO_LEGALE = 'Giuridico-legale',
  INDUSTRIA_MANIFATTURIERO = 'Industria (manifatturiero)',
  INDUSTRIA_SERVIZI = 'Industria (servizi)',
  INFORMAZIONE_E_COMUNICAZIONE = 'Informazione e comunicazione',
  ISTRUZIONE_E_FORMAZIONE = 'Istruzione e formazione',
  MARITTIMO_NAVALE = 'Marittimo-navale',
  MEDICO_SANITARIO = 'Medico-sanitario',
  POLITICO = 'Politico',
  PSICOLOGICO_E_SERVIZI_ALLA_PERSONA = 'Psicologico e servizi alla persona',
  RISTORAZIONE_E_TURISMO = 'Ristorazione e turismo',
  SOCIALE_NO_PROFIT = 'Sociale-No profit',
  SPORT = 'Sport',
  TRASPORTI = 'Trasporti',
  ALTRO = 'Altro…',
}

export enum ContractualLevel {
  CL1 = 'Apicale con funzioni di Dirigente/Titolare',
  CL2 = 'Intermedio con funzioni di responsabilità/Alta specializzaione',
  CL3 = 'Di base con funzioni esecutive/operative',
  CL4 = 'Apprendista/in prova',
}

export enum CompanyType {
  SOCIETA_DI_PERSONE = 'Società di persone',
  SOCIETA_DI_CAPITALI = 'Società di capitali',
  SOCIETA_COOPERATIVE = 'Società cooperativa',
  IMPRESA_ARTIGIANA = 'Impresa artigiana',
  IMPRESA_AGRICOLA = 'Impresa agricola',
  IMPRESA_FAMILIARE = 'Impresa familiare',
  ASSOCIAZIONE_TEMPORANEA_DI_IMPRESE = 'Associazione temporanea di imprese',
  ASSOCIAZIONE_IN_PARTECIPAZIONE = 'Associazione in partecipazione',
}

export type StepComponentProps = {
  steps: StepModel[];
  applicationAppeal?: IAppeal;
  applicationAppealResidence?: IResidence;
  currentStep: StepModel;
  prevStep: StepModel | undefined;
  nextStep: StepModel | undefined;
  profile: updateUserProfileType | undefined;
  profileFetchStatus: FetchStatus;
  setProfile: (profile: updateUserProfileType) => void;
};

export type StepModel = {
  name: string;
  path: string;
  icon: IconType;
  component: React.FC<StepComponentProps>;
};

export type DecodedToken = {
  _id: string;
  email: string;
  iat: number;
  exp: number;
};
export type SessionModel = {
  accessToken: string;
  refreshToken: string;
};

export type UserSessionModel = DecodedToken & SessionModel;

export type VerifiedUserModel = {
  email: string;
  refreshToken: string;
  accessToken: string;
  exp: string;
};
export enum AdmDocumentType {
  PHOTO = 'PHOTO',
  CURRICULM_VITATE = 'CURRICULM_VITATE',
  IDENTITY_CARD = 'IDENTITY_CARD',
  DIPLOMA = 'DIPLOMA',
  LAST_SCHOOL_REPORT = 'LAST_SCHOOL_REPORT',
  CERTIFICATE_EXAMS_EXAMS_TAKEN_AMD_STUDY_PLAN = 'CERTIFICATE_EXAMS_EXAMS_TAKEN_AMD_STUDY_PLAN',
  DEGREE_CERTIFICATE_IF_ALREADY_GRADUATED = 'DEGREE_CERTIFICATE_IF_ALREADY_GRADUATED',
  DEGREE_CERTIFICATE = 'DEGREE_CERTIFICATE',
  LETTER_OF_ADMISSION_TO_DOCTORATE = 'LETTER_OF_ADMISSION_TO_DOCTORATE',
  CERTIFICATE_OF_ENROLLMENT = 'CERTIFICATE_OF_ENROLLMENT',
  TRANSCRIPT_OF_RECORDS_AND_DEGREE_CERTIFICATE = 'TRANSCRIPT_OF_RECORDS_AND_DEGREE_CERTIFICATE',
  STUDY_PLAN = 'STUDY_PLAN',
  DIPLOMA_SUPPLEMENT = 'DIPLOMA_SUPPLEMENT',
  ISEE = 'ISEE',
  DSU = 'DSU',
}
export enum ResidenceMode {
  FIRST_ENROLLMENT = 'FIRST_ENROLLMENT',
  ALREADY_ENROLLED = 'ALREADY_ENROLLED',
  GRADUATED = 'GRADUATED',
  INTERNATIONAL = 'INTERNATIONAL',
}
