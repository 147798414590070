import React, { useContext, useState, useEffect, createContext } from 'react';

import { FetchStatus } from '../types';

import { FaviconLoader } from 'components/Shared/Spinner/FaviconLoader';
import { IAppeal, IResidence } from 'types/api/residences';
import { getAppealsFn, getResidencesFn } from 'api/residencesApi';

type AppProps = {
  children?: React.ReactNode;
};

export type ResidencesContextProps = {
  residences: IResidence[];
  residencesFetchStatus: FetchStatus;
  appeals: IAppeal[];
  appealsFetchStatus: FetchStatus;
};

export const ResidencesContext = createContext<ResidencesContextProps>({
  residences: [],
  residencesFetchStatus: FetchStatus.UNDEFINED,
  appeals: [],
  appealsFetchStatus: FetchStatus.UNDEFINED,
});

export function ResidencesContextProvider({ children }: AppProps) {
  const [residences, setResidences] = useState<IResidence[]>([]);
  const [residencesFetchStatus, setResidencesFetchStatus] = useState<FetchStatus>(FetchStatus.UNDEFINED);
  const [appeals, setAppeals] = useState<IAppeal[]>([]);
  const [appealsFetchStatus, setAppealsFetchStatus] = useState<FetchStatus>(FetchStatus.UNDEFINED);

  async function fetchResidences() {
    try {
      setResidencesFetchStatus(FetchStatus.LOADING);
      const fetchedResidences = await getResidencesFn();
      setResidences(fetchedResidences);
      setResidencesFetchStatus(FetchStatus.SUCCESS);
    } catch (e: any) {
      // console.error(e, typeof(e))
      setResidencesFetchStatus(FetchStatus.ERROR);
    }
  }

  async function fetchAppeals() {
    try {
      setAppealsFetchStatus(FetchStatus.LOADING);
      const fetchedAppeals = await getAppealsFn();
      setAppeals(fetchedAppeals);
      setAppealsFetchStatus(FetchStatus.SUCCESS);
    } catch (e: any) {
      // console.error(e, typeof(e))
      setAppealsFetchStatus(FetchStatus.ERROR);
    }
  }

  useEffect(() => {
    fetchResidences();
    fetchAppeals();
  }, []);

  return (
    <ResidencesContext.Provider
      // Add required values to the value prop within an object (my preference)
      value={{
        residences,
        residencesFetchStatus,
        appeals,
        appealsFetchStatus,
      }}
    >
      {[FetchStatus.LOADING, FetchStatus.UNDEFINED].some((status) =>
        [residencesFetchStatus, appealsFetchStatus].includes(status)
      ) ? (
        <FaviconLoader />
      ) : (
        children
      )}
    </ResidencesContext.Provider>
  );
}

// Create a hook to use the APIContext, this is a Kent C. Dodds pattern
export function useResidences() {
  const context = useContext(ResidencesContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
