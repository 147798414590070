import { useApplication } from 'context/applicationContext';
import { useResidences } from 'context/residencesContext';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResidenceMode } from 'types';
import { IAppeal } from 'types/api/residences';

export const CreateApplication = () => {
  const { t, i18n } = useTranslation();
  const { residences, appeals } = useResidences();
  const [mode, setMode] = useState<'national' | 'international'>();
  const [city, setCity] = useState<string>();
  const [appeal, setAppeal] = useState<IAppeal>();
  const [type, setType] = useState<ResidenceMode>();
  const [promoCode, setPromoCode] = useState<string | undefined>(undefined);
  const { application, createApplicationRequest, updateApplicationAppealRequest } = useApplication();

  useEffect(() => {
    setCity(undefined);
    setType(undefined);
    setAppeal(undefined);
  }, [mode]);

  useEffect(() => {
    setType(undefined);
    setAppeal(undefined);
  }, [city]);

  useEffect(() => {
    setAppeal(undefined);
  }, [type]);

  const cities = useMemo(() => {
    if (!mode) {
      return [];
    }
    return residences
      .filter((residence) => {
        // filtra solo le residenze che soddisfano la modalità di bando tra internazionali e non
        if (mode === 'national') {
          return (
            residence.mode !== ResidenceMode.INTERNATIONAL && appeals.map((a) => a.residence_id).includes(residence._id)
          );
        }
        return (
          residence.mode === ResidenceMode.INTERNATIONAL && appeals.map((a) => a.residence_id).includes(residence._id)
        );
      })
      .reduce<string[]>((list, residence) => {
        if (!list.includes(residence.city)) {
          list.push(residence.city);
        }
        return list;
      }, []);
  }, [mode, residences, appeals]);

  const types = useMemo(() => {
    if (!mode || !city || !residences?.length) {
      return [];
    }
    return residences.reduce<ResidenceMode[]>((list, residence) => {
      if (
        ((mode === 'national' && residence.mode !== ResidenceMode.INTERNATIONAL) ||
          (mode === 'international' && residence.mode === ResidenceMode.INTERNATIONAL)) &&
        residence.city === city &&
        !list.includes(residence.mode)
      ) {
        list.push(residence.mode);
      }
      return list;
    }, []);
  }, [residences, mode, city]);

  const availableAppeals = useMemo(() => {
    if (!mode || !city || !type || !appeals?.length) {
      return [];
    }
    const availableResidences = residences.filter((residence) => {
      return residence.city === city && residence.mode === type;
    });
    const list = appeals.filter((a) => availableResidences.map((residence) => residence._id).includes(a.residence_id));

    return list;
  }, [appeals, residences, mode, city, type]);

  useEffect(() => {
    if (!type && types?.length === 1) {
      setType(types[0]);
    }
  }, [types]);

  useEffect(() => {
    if (!appeal && availableAppeals?.length === 1) {
      setAppeal(availableAppeals[0]);
    }
  }, [availableAppeals]);

  useEffect(() => {
    if (appeal) {
      // forza la selezione della lingua in inglese
      i18n.changeLanguage(mode === 'international' ? 'en' : 'it');
      const residence = residences.find((r) => r._id === appeal.residence_id)!;
      if (application) updateApplicationAppealRequest(appeal._id, promoCode);
      else createApplicationRequest(appeal._id, residence.mode, promoCode);
    }
  }, [appeal]);

  if (!appeals?.length) {
    return (
      <div className="w-full flex flex-col gap-1 items-center">
        <p className="text-lg font-bold">{t('CreateApplication.noAppeal.title')}</p>
        <p className="text-md font-medium">{t('CreateApplication.noAppeal.description')}</p>
      </div>
    );
  }

  return (
    <div className="space-y-12">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full md:max-w-xl">
        <label
          className={`flex flex-col cursor-pointer rounded border text-center hover:shadow-xl ${
            mode === 'national' ? 'shadow-xl shadow-blue-500/50' : 'shadow'
          }`}
          onClick={() => setMode('national')}
        >
          <input type="radio" name="applicationType" value="national" className="sr-only" />
          <div className="flex-none h-28 w-full p-4 bg-bluemazza rounded-t">
            <h3 className="text-xl font-bold text-white">{t('CreateApplication.nationalMode.title')}</h3>
          </div>
          <div className="flex grow justify-center items-center p-4">
            <p>{t('CreateApplication.nationalMode.description')}</p>
          </div>
        </label>

        <label
          className={`flex flex-col cursor-pointer rounded border text-center hover:shadow-xl ${
            mode === 'international' ? 'shadow-xl shadow-blue-500/50' : 'shadow'
          }`}
          onClick={() => setMode('international')}
        >
          <input type="radio" name="applicationType" value="international" className="sr-only" />
          <div className="flex-none h-28 w-full p-4 bg-bluemazza rounded-t">
            <h3 className="text-xl font-bold text-white">{t('CreateApplication.internationalMode.title')}</h3>
          </div>
          <div className="flex grow justify-center items-center p-4">
            <p>{t('CreateApplication.internationalMode.description')}</p>
          </div>
        </label>
      </div>
      {cities?.length ? (
        <div className="space-y-4">
          <h4 className="text-lg font-semibold text-bluemazza">{t('CreateApplication.city.title')}</h4>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full md:max-w-xl">
            {cities.map((c) => (
              <label
                key={`${mode}_${c}`}
                className={`border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 cursor-pointer focus:outline-none hover:shadow-xl ${
                  city === c ? 'shadow-xl shadow-blue-500/50' : 'shadow'
                }`}
              >
                <input type="radio" name="city" value={c} className="sr-only" onChange={() => setCity(c)} />
                <p>{c}</p>
              </label>
            ))}
          </div>
          <p className="text-sm text-neutral-600">{t('CreateApplication.city.description')}</p>
        </div>
      ) : null}
      {mode && !cities?.length ? (
        <div className="w-full flex flex-col gap-1 items-center">
          <p className="text-lg font-bold">{t('CreateApplication.noResidence.title')}</p>
          <p className="text-md font-medium">{t('CreateApplication.noResidence.description')}</p>
        </div>
      ) : null}
      {types?.length > 1 ? (
        <div className="space-y-4">
          <h4 className="text-lg font-semibold text-bluemazza">{t('CreateApplication.types.title')}</h4>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full md:max-w-xl">
            {types.map((rt, index) => (
              <label
                key={`${mode}_${city}_${rt}`}
                className={`border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 cursor-pointer focus:outline-none hover:shadow-xl ${
                  type === rt ? 'shadow-xl shadow-blue-500/50' : 'shadow'
                }`}
              >
                <input type="radio" name="type" value={rt} className="sr-only" onChange={() => setType(rt)} />

                <p className="py-3 px-3 w-full text-center">
                  {t(`CreateApplication.types.${rt}`)}
                  {index}
                </p>
              </label>
            ))}
          </div>
          <p className="text-sm text-neutral-600">{t('CreateApplication.types.description')}</p>
        </div>
      ) : null}
      {availableAppeals?.length > 1 ? (
        <div className="space-y-4">
          <h4 className="text-lg font-semibold text-bluemazza">{t('CreateApplication.appeals.title')}</h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full md:max-w-xl">
            {availableAppeals.map((a) => (
              <label
                key={a._id}
                className={`border rounded-md bg-bluemazza text-white text-center flex flex-col items-center justify-center text-sm font-medium uppercase sm:flex-1 cursor-pointer focus:outline-none hover:shadow-xl`}
              >
                <input type="radio" name="appeal" value={a._id} className="sr-only" onChange={() => setAppeal(a)} />
                <p className="py-3 px-3 w-full">{residences.find((r) => r._id === a.residence_id)!.name}</p>
              </label>
            ))}
          </div>
          <p className="text-sm text-neutral-600">{t('CreateApplication.appeals.description')}</p>
        </div>
      ) : null}
    </div>
  );
};
