import axios from 'axios';
import { ResidenceMode } from 'types';
import { IApplication, ICreateApplication, IQuestion, IUpdateApplication } from 'types/api/applications';

export async function getQuestionsFn(mode: ResidenceMode): Promise<IQuestion[]> {
  const response = await axios.get(`/api/applications/questions?mode=${mode}`);
  return response.data;
}

export async function getApplicationFn(): Promise<IApplication> {
  const response = await axios.get<IApplication>('/api/applications/application');
  return response.data;
}

export async function createApplicationFn(body: ICreateApplication): Promise<IApplication> {
  const response = await axios.post<IApplication>('/api/applications/application', body);
  return response.data;
}

export async function updateApplicationFn(body: IUpdateApplication): Promise<IApplication> {
  const response = await axios.patch<IApplication>('/api/applications/application', body);
  return response.data;
}

export async function deleteApplicationFn(): Promise<void> {
  await axios.delete(`/api/applications/application`);
}

export async function deleteApplicationUploadFn(key: string): Promise<IApplication> {
  const response = await axios.delete(`/api/applications/application/uploads/${key}`);
  return response.data;
}

export async function downloadApplicationUploadFn(key: string): Promise<any> {
  const response = await axios.get(`/api/applications/application/uploads/${key}`, { responseType: 'blob' });
  return response.data;
}

export async function uploadApplicationUploadFn(formData: FormData): Promise<any> {
  const response = await axios.put(`/api/applications/application/uploads`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    maxContentLength: 100000000,
    maxBodyLength: 1000000000,
  });
  return response.data;
}

export async function downloadAdmissionReqPdfFn(): Promise<any> {
  const response = await axios.get(`/api/applications/application/admReqPdf`, { responseType: 'blob' });
  return response.data;
}
