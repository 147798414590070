import React, { useState, useEffect, Fragment } from 'react';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { FamilyMemberType, MaritalStatusType, ProfessionType, StepComponentProps } from 'types';
import { familyCompositionSchema, familyCompositionType } from 'types/zod';

import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';

import { FaTrashAlt, FaUsers } from 'react-icons/fa';

import FormStepManager from 'components/Application/Form/FormStepManager/FormStepManager';
import BlankLoader from 'components/Shared/Spinner/BlankLoader';
import { CustomDatePickerInput } from 'components/reactDatepicker';
import FamilyMemberDialog from 'components/Application/Form/FamilyComposition/FamilyMemberDialog/FamilyMemberDialog';
import DeleteConfirmationDialog from 'components/Application/Form/FamilyComposition/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import FamilyCompositionPerson from 'components/Application/Form/FamilyComposition/FamiltyCompositionPerson';
import { useTranslation } from 'react-i18next';

function DescriptionBox() {
  const { t } = useTranslation();
  return (
    <div className="animate-fade-in-down md:animate-fade-in-left mt-4 md:mt-0 flex w-full md:w-4/12 flex-col items-center md:items-start gap-4">
      <h3 className="flex flex-row items-center text-black font-bold text-xl">
        <FaUsers />
        <span className="pl-2">{t('FamilyComposition.a1')}</span>
      </h3>
      <p className="text-black text-center md:text-left font-medium text-md">{t('FamilyComposition.a2')}</p>
      <ul className="list-disc text-black font-medium text-md pl-4">
        <li>{t('FamilyComposition.a3')}</li>
        {/* <li>È richiesto almeno un membro per proseguire</li> */}
      </ul>
    </div>
  );
}

type FormValues = {
  familyComposition: Array<familyCompositionType>;
};
export default function FamilyComposition({
  steps,
  currentStep,
  nextStep,
  prevStep,
  profile,
  setProfile,
}: StepComponentProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { control, register, getValues, formState, resetField } = useForm({
    resolver: zodResolver(familyCompositionSchema),
  });

  const { errors } = formState;

  const { fields, append, remove } = useFieldArray<FormValues, 'familyComposition', 'id'>({
    control: control as any,
    name: 'familyComposition', // unique name for your Field Array
  });

  const [isStepLoading, setIsStepLoading] = useState<boolean>(true);

  const [isMemberDialogOpen, setIsMemberDialogOpen] = useState<boolean>(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);
  const [idxToDelete, setIdxToDelete] = useState<number | undefined>(undefined);

  const [isStepComplete, setIsStepComplete] = useState<boolean>(false);

  const fieldRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    fieldRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    setIsStepComplete(true);
  }, [fields]);

  useEffect(() => {
    // console.log(fields)
    if (profile?.familyComposition) {
      if (JSON.stringify(fields) !== JSON.stringify(profile.familyComposition)) remove();
      append(profile.familyComposition, { focusIndex: 0 });
    }
    setIsStepLoading(false);
  }, [profile]);

  const submitForm = () => {
    // console.log(`${currentStep.name} form submitted: `, getValues().familyComposition)
    setProfile({
      ...profile,
      familyComposition: getValues().familyComposition,
    });
    nextStep && navigate(`/application/form/${nextStep.path}`);
  };

  const addFamilyMember = (data: familyCompositionType) => append(data);

  const removeSelectedMember = () => idxToDelete !== undefined && remove(idxToDelete);

  return (
    <div ref={fieldRef} className="w-full flex flex-col md:flex-row justify-between flex-wrap">
      <FormStepManager
        profile={profile}
        steps={steps}
        prevStep={prevStep}
        currentStep={currentStep}
        nextStep={isStepComplete ? submitForm : undefined}
      />
      {isStepLoading ? (
        <BlankLoader />
      ) : (
        <>
          <DescriptionBox />
          <DeleteConfirmationDialog
            isOpen={deleteConfirmationOpen}
            onClose={() => setDeleteConfirmationOpen(false)}
            removeMember={removeSelectedMember}
          />
          <FamilyMemberDialog
            addMember={addFamilyMember}
            isOpen={isMemberDialogOpen}
            onClose={() => setIsMemberDialogOpen(false)}
          />
          <div className="animate-fade-in-up md:animate-fade-in-right w-full md:w-8/12 flex flex-col items-center md:pl-8 gap-4 mt-8 md:mt-0">
            <div className="w-full flex flex-row items-center justify-between">
              <h3 className="text-bluemazza font-bold text-lg ">{t('FamilyComposition.a6')}</h3>
              <button
                onClick={() => setIsMemberDialogOpen(true)}
                className="transition-colors ease-in-out duration-300  bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
              >
                {t('FamilyComposition.a4')}
              </button>
            </div>
            {fields.length === 0 && (
              <h3 className="text-black font-bold text-lg underline ">{t('FamilyComposition.a5')}</h3>
            )}
            {fields.map((field, i) => (
              <FamilyCompositionPerson
                key={field.id}
                register={register}
                index={i}
                control={control}
                resetField={resetField}
                errors={errors}
                onRemove={() => {
                  setIdxToDelete(i);
                  setDeleteConfirmationOpen(true);
                }}
                showJobsInfo={!profile?.registry?.notSubmitIsee}
              />
            ))}
            <button
              className="bluemazza-submit-button md:self-end"
              disabled={!isStepComplete}
              onClick={isStepComplete ? submitForm : undefined}
            >
              {t('FamilyComposition.a7')}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
